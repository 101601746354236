import { Spacing } from '../..'

export const cardStyle = (theme) => ({
  link: {
    textDecoration: 'none'
  },
  shadowCard: {
  },
  cardGroup: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: ({ height }) => height,
    width: '100%',
    padding: () => Spacing(5)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: () => Spacing(0, 0, 4, 0)
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  playIcon: {
    color: theme.neutral[0],
    fontSize: 6,
    backgroundColor: theme.primary[0],
    borderRadius: 8,
    marginRight: 8,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progressionGroup: {
    display: 'flex',
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap'
  },
  progressionGroupBought: {
    borderRadius: 4,
    position: 'relative',
    maxWidth: 65,
    display: 'flex',
    marginTop: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    backgroundColor: theme.neutral[0],
    overflow: 'hidden'
  },
  progressionBarBought: {
    width: 65,
    height: 21,
    borderRadius: 4,
    backgroundColor: theme.neutral[0],
    '&:after': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      content: '""',
      display: 'block',
      width: ({ completionPercentage }) => `${completionPercentage}%`,
      height: '100%',
      backgroundColor: theme.success[0]
    }
  },
  completionPercentageAbsolute: {
    position: 'absolute',
    left: 13
  },
  certificatIcon: {
    fontSize: 14,
    color: theme.primary[0]
  },
  binIcon: {
    fontSize: 14,
    color: theme.neutral[0],
    cursor: 'pointer',
    '&:hover': {
      color: theme.error[0]
    }
  },
  progressionBar: {
    width: '100%',
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.neutral[0],
    '&:after': {
      content: '""',
      display: 'block',
      width: ({ progression }) => `${progression}%`,
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.success[0]
    }
  },
  freeBadge: {
    width: 65,
    border: `1px solid ${theme.success[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    textTransform: 'uppercase'
  },
  promoBadge: {
    width: 65,
    border: `1px solid ${theme.error[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    textTransform: 'uppercase'
  }
})
