import {
  Row,
  Col,
  TypographyElement,
  Paper,
  Icons,
  Icon,
  MediaQuery,
  Button,
  ButtonVariation,
  Container
} from 'UI/'
import Text from '../text.json'
import { createUseStyles } from 'react-jss'
import style from '../style'
import { HomePath } from 'navigation/Routes'

const useStyle = createUseStyles(style)

export default ({ phone, email, signup, isElligableForTrial, setTrialState, startTrialOnboarding, goToLink, isConnected, activeSubscription }) => {
  const { paper, icon, buttonContainer, leftMotif, topRightMotif, bottomRightMotif } = useStyle()
  const StartTrialSubscription = () => {
    setTrialState(true)
    if (isConnected) {
      goToLink(HomePath)
      startTrialOnboarding()
    } else {
      signup()
    }
  }
  return (
    <>
      <MediaQuery HideOnMobile HideOnTablet>
        <div className={leftMotif} />
        <div className={topRightMotif} />
      </MediaQuery>
      <div className={bottomRightMotif} />
      <Container>
        <Row justify='between' mobileGap={10}>
          <Col grid={6} mgrid={12} sgrid={12} direction='column' pos='center'>
            <TypographyElement
              variant='heading3'
              Component='p'
              spacingTablet='0 0 16px 0'
              spacingMobile='0 0 16px 0'
            >
              {Text.title}
            </TypographyElement>
          </Col>
          <Col grid={6} mgrid={12} sgrid={12} display='flex' direction='column' pos='mid' align='bot' alignMobile='mid' alignTablet='mid' gap={5} mobileGap={0}>
            <Paper className={paper}>
              <Icon iconName={Icons.smartPhone} style={icon} />
              <TypographyElement
                variant='body1'
                Component='a'
                cursor='pointer'
                href={`tel:${phone}`}
              >
                {phone}
              </TypographyElement>
            </Paper>
            <Paper className={paper}>
              <Icon iconName={Icons.envelope} style={icon} />
              <TypographyElement
                variant='body1'
                Component='a'
                cursor='pointer'
                href={`mailto:${email}`}
              >
                {email}
              </TypographyElement>
            </Paper>
          </Col>
        </Row>
        {
          isElligableForTrial && !activeSubscription
            ? (
              <div className={buttonContainer}>
                <Button
                  textProps={{ spacing: '0 8px' }}
                  width={300}
                  handleClick={StartTrialSubscription}
                  variation={ButtonVariation.confirmation}
                  label={Text.freeTrial}
                  dataTest='signup-button'
                />
              </div>)
            : null
        }
      </Container>
    </>
  )
}
