export const defaultCategories = [
  {
    Id: 'e930ec21-08e6-4b42-a530-02f550265083',
    Name: 'Développement',
    Slug: 'developpement',
    Description: 'Développement',
    Photo: 'icon-categories-developpement.svg',
    IconUrl: null,
    NumberOfCourses: 181,
    TotalCoursePath: 26,
    TotalWebinars: 0,
    TotalFreeCourses: 12,
    Order: 0,
    MetaTitle: 'Formation Développement - Devenez Expert en Développement avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en développement et devenez un expert. Apprenez les langages de programmation, les frameworks modernes et bien plus encore avec nos cours en ligne structurés et accessibles.',
    Introduction: "Nos formations en développement offrent une immersion complète pour les professionnels cherchant à approfondir leurs compétences. Nos parcours sont conçus pour vous aider à maîtriser les langages de programmation les plus demandés et les frameworks modernes. Grâce à une approche structurée, vous développerez des compétences solides et avancées, essentielles pour exceller dans le domaine du développement. Commencez dès aujourd'hui votre formation développement avec Alphorm.",
    CoursePathMetaTitle: 'Parcours de Formation en Développement - Programme Structuré',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en développement, conçus pour offrir une progression structurée des compétences en programmation.',
    CoursePathIntroduction: 'Nos parcours de formation en développement offrent une progression structurée des compétences en programmation. Chaque parcours est conçu pour guider les professionnels de manière progressive, de la maîtrise des langages de programmation aux frameworks modernes.'
  },
  {
    Id: 'd7305f3f-ab63-4f23-abe2-3662fff390f2',
    Name: '3D et Animation',
    Slug: '3d-et-animation',
    Description: '3D et Animation',
    Photo: 'icon-categories-cao-dao.svg',
    IconUrl: null,
    NumberOfCourses: 135,
    TotalCoursePath: 36,
    TotalWebinars: 0,
    TotalFreeCourses: 2,
    Order: 0,
    MetaTitle: "Formation 3D et Animation - Maîtrisez les Techniques Avancées de Modélisation et d'Animation",
    MetaDescription: 'Explorez nos formations en 3D et animation pour maîtriser les techniques de modélisation, texturing et animation. Devenez un expert avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en 3D et animation sont conçues pour les professionnels désirant perfectionner leurs compétences dans ce domaine. Apprenez les techniques avancées de modélisation, de texturing et d'animation grâce à nos cours en ligne complets et structurés. Que vous travailliez dans le cinéma, le jeu vidéo ou l'architecture, nos parcours vous fourniront les compétences nécessaires pour exceller et innover. Commencez votre formation 3D et animation dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en 3D et Animation - Techniques Avancées',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en 3D et animation, conçus pour offrir une progression structurée des compétences en modélisation et animation.',
    CoursePathIntroduction: 'Nos parcours de formation en 3D et animation offrent une progression structurée des compétences en modélisation, texturing et animation. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques avancées.'
  },
  {
    Id: 'ba45ab70-968f-4add-8756-35de31f325c0',
    Name: 'Virtualisation',
    Slug: 'virtualisation',
    Description: 'Virtualisation',
    Photo: 'icon-categories-virtualisation.svg',
    IconUrl: null,
    NumberOfCourses: 80,
    TotalCoursePath: 19,
    TotalWebinars: 0,
    TotalFreeCourses: 4,
    Order: 0,
    MetaTitle: 'Formation Virtualisation - Devenez Expert en Technologie de Virtualisation avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en virtualisation et maîtrisez les technologies de virtualisation. Apprenez à gérer et déployer des environnements virtualisés avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en virtualisation sont conçues pour les professionnels souhaitant maîtriser les technologies de virtualisation. Apprenez à gérer et à déployer des environnements virtualisés avec nos cours en ligne complets et structurés. Que vous soyez impliqué dans l'administration système, la gestion de serveurs ou le cloud computing, nos parcours vous fourniront les compétences nécessaires pour exceller et optimiser vos infrastructures. Commencez votre formation virtualisation dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Virtualisation - Gestion des Environnements',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en virtualisation, conçus pour offrir une progression structurée des compétences en gestion des environnements virtualisés.',
    CoursePathIntroduction: 'Nos parcours de formation en virtualisation offrent une progression structurée des compétences en gestion des environnements virtualisés. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques de gestion avancées.'
  },
  {
    Id: 'a809f91b-244d-4183-8793-6faf329e7693',
    Name: 'Sécurité',
    Slug: 'securite',
    Description: 'Sécurité',
    Photo: 'icon-categories-securite.svg',
    IconUrl: null,
    NumberOfCourses: 79,
    TotalCoursePath: 14,
    TotalWebinars: 0,
    TotalFreeCourses: 4,
    Order: 0,
    MetaTitle: 'Formation Sécurité Informatique - Devenez Expert en Cybersécurité avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en sécurité informatique et maîtrisez la cybersécurité. Apprenez à protéger les systèmes et les données avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en sécurité informatique sont conçues pour les professionnels souhaitant se spécialiser en cybersécurité. Apprenez à protéger les systèmes, les réseaux et les données contre les cybermenaces grâce à nos cours en ligne complets et structurés. Que vous soyez impliqué dans l'administration réseau, le développement sécurisé ou la gestion des risques, nos parcours vous fourniront les compétences nécessaires pour exceller. Commencez votre formation sécurité informatique dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Sécurité Informatique - Protection Avancée',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en sécurité informatique, conçus pour offrir une progression structurée des compétences en cybersécurité.',
    CoursePathIntroduction: 'Nos parcours de formation en sécurité informatique offrent une progression structurée des compétences en cybersécurité. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques de protection avancées.'
  },
  {
    Id: '9d797625-4d1c-4a24-afa4-73b94eddeca3',
    Name: 'Bureautique',
    Slug: 'bureautique',
    Description: 'Bureautique',
    Photo: 'icon-categories-bureautique.svg',
    IconUrl: null,
    NumberOfCourses: 94,
    TotalCoursePath: 12,
    TotalWebinars: 0,
    TotalFreeCourses: 7,
    Order: 0,
    MetaTitle: 'Formation Bureautique - Maîtrisez les Outils Bureautiques avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en bureautique et devenez un expert des outils de productivité. Apprenez à utiliser Word, Excel, PowerPoint et plus encore avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en bureautique sont conçues pour les professionnels cherchant à optimiser leur productivité en maîtrisant les outils bureautiques. Apprenez à utiliser efficacement Word, Excel, PowerPoint et d'autres logiciels essentiels grâce à nos cours en ligne complets et structurés. Que vous soyez débutant ou souhaitiez approfondir vos compétences, nos parcours vous fourniront les connaissances nécessaires pour exceller. Commencez votre formation bureautique dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Bureautique - Maîtrise des Outils',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en bureautique, conçus pour offrir une progression structurée des compétences en utilisation des outils bureautiques.',
    CoursePathIntroduction: 'Nos parcours de formation en bureautique offrent une progression structurée des compétences en utilisation des outils bureautiques. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques de maîtrise avancées.'
  },
  {
    Id: '977a26d2-89f2-41d5-be90-6a84365aabb3',
    Name: 'Réseaux',
    Slug: 'reseaux',
    Description: 'Réseaux',
    Photo: 'icon-categories-reseaux.svg',
    IconUrl: null,
    NumberOfCourses: 68,
    TotalCoursePath: 12,
    TotalWebinars: 0,
    TotalFreeCourses: 6,
    Order: 0,
    MetaTitle: 'Formation Réseaux Informatique - Devenez Expert en Réseaux avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en réseaux informatique et maîtrisez la gestion des réseaux. Apprenez à configurer, sécuriser et administrer les réseaux avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en réseaux informatique sont conçues pour les professionnels souhaitant se spécialiser dans la gestion des réseaux. Apprenez à configurer, sécuriser et administrer des réseaux de toutes tailles grâce à nos cours en ligne complets et structurés. Que vous soyez impliqué dans l'administration réseau, la sécurité des réseaux ou la gestion des infrastructures, nos parcours vous fourniront les compétences nécessaires pour exceller. Commencez votre formation réseaux informatique dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Réseaux Informatique - Administration Avancée',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en réseaux informatique, conçus pour offrir une progression structurée des compétences en gestion des réseaux.',
    CoursePathIntroduction: "Nos parcours de formation en réseaux informatique offrent une progression structurée des compétences en gestion des réseaux. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques d'administration avancées."
  },
  {
    Id: '19bb7b7a-f80c-46ba-93d1-c52ea9d8f26e',
    Name: 'Bases de données',
    Slug: 'bases-de-donnees',
    Description: 'Bases de données',
    Photo: 'icon-categories-bases-de-donnees.svg',
    IconUrl: null,
    NumberOfCourses: 33,
    TotalCoursePath: 12,
    TotalWebinars: 0,
    TotalFreeCourses: 0,
    Order: 0,
    MetaTitle: 'Formation Bases de Données - Maîtrisez la Gestion des Données avec nos Cours en Ligne',
    MetaDescription: 'Découvrez nos formations en bases de données et devenez un expert en gestion des données. Apprenez à concevoir, administrer et sécuriser des bases de données avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en bases de données sont conçues pour les professionnels cherchant à maîtriser la gestion des données. Apprenez à concevoir, administrer et sécuriser des bases de données grâce à nos cours en ligne complets et structurés. Que vous soyez impliqué dans le développement, l'administration ou l'analyse de données, nos parcours vous fourniront les compétences nécessaires pour exceller. Commencez votre formation bases de données dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Bases de Données - Administration Avancée',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en bases de données, conçus pour offrir une progression structurée des compétences en gestion des données.',
    CoursePathIntroduction: "Nos parcours de formation en bases de données offrent une progression structurée des compétences en gestion des données. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques d'administration avancées."
  },
  {
    Id: '0df9e561-41d4-4e5f-8bff-2c92ddfa9903',
    Name: 'Infrastructure',
    Slug: 'infrastructure',
    Description: 'Infrastructure',
    Photo: 'icon-categories-infrastructure.svg',
    IconUrl: null,
    NumberOfCourses: 103,
    TotalCoursePath: 11,
    TotalWebinars: 0,
    TotalFreeCourses: 6,
    Order: 0,
    MetaTitle: "Formation Infrastructure Informatique - Maîtrisez les Fondamentaux de l'Infrastructure IT avec nos Cours en Ligne",
    MetaDescription: 'Découvrez nos formations en infrastructure informatique et devenez un expert. Apprenez à concevoir, déployer et gérer des infrastructures IT avec nos cours en ligne complets et structurés.',
    Introduction: "Nos formations en infrastructure informatique sont conçues pour les professionnels souhaitant maîtriser la conception, le déploiement et la gestion des infrastructures IT. Apprenez à gérer les serveurs, le stockage, le réseau et la virtualisation grâce à nos cours en ligne complets et structurés. Que vous soyez impliqué dans l'administration système, la gestion des réseaux ou le cloud computing, nos parcours vous fourniront les compétences nécessaires pour exceller. Commencez votre formation infrastructure informatique dès aujourd'hui avec nous.",
    CoursePathMetaTitle: 'Parcours de Formation en Infrastructure Informatique - Gestion Avancée',
    CoursePathMetaDescription: 'Explorez nos parcours de formation en infrastructure informatique, conçus pour offrir une progression structurée des compétences en gestion des infrastructures IT.',
    CoursePathIntroduction: 'Nos parcours de formation en infrastructure informatique offrent une progression structurée des compétences en gestion des infrastructures IT. Chaque parcours est conçu pour guider les professionnels de manière progressive, avec des techniques de gestion avancées.'
  }
]
