import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { tableOfContentsStyle } from './tableOfContents.style'
import VideoProgression from '../videoProgression/videoProgression'
import CourseProgressionHeader from '../courseProgressionHeader/courseProgressionHeader'
import EndOfCourseQuiz from '../endOfCourseQuiz/endOfCourseQuiz'
import { ViewState, VideoState } from '../../constants/videoConst'
import moment from 'moment'

const formatMinutesDuration = seconds => {
  const duration = moment.duration(seconds, 'seconds')
  return `${duration.hours() === 0 ? '' : duration.hours() + 'h'}${duration.minutes()}min${duration.seconds()}s`
}

const TableOfContents = ({
  chapterClick,
  onVideoClick,
  buildVideoLink,
  offline,
  pauseDownload,
  playDownload,
  deleteVideo,
  downloadVideo,
  chapters,
  currentVideo,
  learningMode,
  standAloneChapters,
  expandAll = false,
  onlineStatus,
  baseUrl,
  AllowVideoClick,
  endOfCourseQuizProps,
  noBorder
}) => {
  const useStyle = createUseStyles(tableOfContentsStyle)
  const classes = useStyle({
    chapterClick,
    onVideoClick,
    buildVideoLink,
    offline,
    pauseDownload,
    playDownload,
    deleteVideo,
    downloadVideo,
    chapters,
    currentVideo,
    learningMode,
    standAloneChapters,
    expandAll,
    noBorder
  })

  let videoIndex = 0

  return (
    <div className={classes.tableOfContentsBody}>
      {
        chapters && chapters.map((chapter) =>
          <CourseProgressionHeader
            expand={expandAll}
            standAlone={standAloneChapters}
            handleChapterClick={() => chapterClick(chapter)}
            key={chapter.Id}
            chapterIndex={chapter.Order}
            chapterTitle={chapter.Title}
            marginBottom={standAloneChapters && chapter.Order !== chapters.length}
          >
            {
              chapter.Videos.map((video) => {
                console.warn('video', video)
                const link = video.IsLocked ? null : `${baseUrl}/${video.Slug}`
                videoIndex++
                return (
                  <VideoProgression
                    onlineStatus={onlineStatus}
                    offline={offline}
                    showViewState={learningMode}
                    key={video.Id}
                    generateLink={(videoIndex === 1 || videoIndex === 2)}
                    viewState={currentVideo.Id === video.Id ? ViewState.playing : video.WasAlreadyWatched ? ViewState.done : ViewState.unseen}
                    downloadState={video.ControllerState}
                    videoState={learningMode ? VideoState.video : video.IsLocked ? VideoState.locked : VideoState.unlocked}
                    onVideoClick={() => (AllowVideoClick || (learningMode || !video.IsLocked)) && onVideoClick(chapters, chapter, video)}
                    buildVideoLink={buildVideoLink && buildVideoLink(chapter, video)}
                    videoTitle={video.Title}
                    videoDuration={formatMinutesDuration(video.Duration)}
                    order={video.Order}
                    link={link}
                  />
                )
              }
              )
            }

          </CourseProgressionHeader>
        )
      }
      {
        endOfCourseQuizProps &&
          <EndOfCourseQuiz {...endOfCourseQuizProps} />
      }
    </div>
  )
}
TableOfContents.propTypes = {
  /** array of chapters */
  chapters: PropTypes.array,
  /** the video playing */
  currentVideo: PropTypes.object,
  /** the function that play the video */
  onVideoClick: PropTypes.func,
  /** offline boolean for the offline player */
  offline: PropTypes.bool,
  /** function that handle click on a chapter */
  chapterClick: PropTypes.func,
  /** show state of every video */
  learningMode: PropTypes.bool,
  /** expand all cahpter to receal all videos */
  expandAll: PropTypes.bool,
  /** show a design where every chapter is floating alone with the videos */
  standAloneChapters: PropTypes.bool,
  /** EndOfCourseQuiz props (see the EndOfCourseQuiz component for more details) */
  endOfCourseQuizProps: PropTypes.shape({
    tag: PropTypes.string,
    title: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool
  })
}

export default TableOfContents
