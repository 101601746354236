import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Icons } from '../../constants'
import Icon from '../icon/icon'

import { modalStyle } from './modal.style'

const useStyles = createUseStyles(modalStyle)

const Modal = ({
  isOpen,
  title,
  showCloseButton,
  children,
  handleClose,
  justify,
  align,
  margin,
  className,
  closeIconClassName
}) => {
  const convertedAlign = align === 'baseline' ? 'center' : align
  const { modalWrapper, modalInner, overlay, titleStyle, cross } = useStyles({ justify, convertedAlign, margin })

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  return (
    isOpen && (
      <div className={overlay}>
        <div className={modalWrapper}>
          <div className={classNames(modalInner, className)}>
            {title && <h2 className={titleStyle}>{title}</h2>}
            {children}
            <div onClick={handleClose}>
              <Icon
                style={classNames(cross, closeIconClassName)}
                iconName={Icons.close}
              />
            </div>
          </div>
        </div>
      </div>
    )
  )
}

Modal.propTypes = {
  /** if set to true the modal will be visible */
  isOpen: PropTypes.bool,
  /** handle the close button when it clicked */
  handleClose: PropTypes.func,
  /** if set to true the close button will be visible */
  showCloseButton: PropTypes.bool,
  /** the title of the modal */
  title: PropTypes.string,
  /** justify the modal over the screen the constant modalJustify ( left, right, center ) */
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
  /** align the modal over the screen modalAlign ( top, bottom, center ) */
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline']),
  /** margin to give a margin for the wrapper */
  margin: PropTypes.string,
  /** add a classe to the wrapper */
  classeName: PropTypes.string
}

export default Modal
