import fetch from 'core/fetch'
import { allCoursesId, allLearningPathId, BASE_URL_WEB, ItemTypesEnum } from 'constants/'
import { takeLatest, put, select, takeEvery, all } from 'redux-saga/effects'
import { CATEGORY_SELECTED } from 'store/config'
import { ViewItemListDataLayer } from 'helpers'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'
import { pageNotFoundPath } from 'navigation/Routes'
import { defaultCategories } from './categoriesDefaultState'

const defaultTake = 18
const defaultSkip = 0
export const initialCoursesForHomePage = 6
const defaultLearningPathTake = 18
const defaultLearningPathSkip = 0

export const defaultCategory = {
  Id: allCoursesId,
  Name: 'Toutes les formations',
  NumberOfCourses: 0,
  Slug: ''
}

export const defaultLearningPathsCategory = {
  Id: allLearningPathId,
  Name: 'Tous les parcours',
  NumberOfCourses: 0,
  Slug: ''
}

export const freeCoursesCategory = {
  Id: 'free-courses-category',
  Name: 'GRATUITES',
  AlternativeName: 'Formations GRATUITES',
  NumberOfCourses: 0,
  Slug: 'gratuites',
  MetaTitle: 'Formations GRATUITES en Informatique - Développez Vos Compétences IT sans Frais',
  MetaDescription: 'Découvrez nos formations gratuites en informatique et développez vos compétences IT sans frais. Apprenez le développement, la cybersécurité, la gestion des réseaux, la bureautique et plus encore avec nos cours en ligne accessibles à tous.',
  Introduction: "Bienvenue sur notre page de formations gratuites en informatique, conçues pour les professionnels souhaitant développer et perfectionner leurs compétences IT sans frais. Que vous soyez intéressé par le développement, la cybersécurité, la gestion des réseaux, la bureautique ou d'autres domaines, nos cours en ligne gratuits et accessibles vous fourniront les connaissances nécessaires pour exceller dans votre carrière. Explorez nos formations gratuites dès aujourd'hui et commencez votre apprentissage avec nous."
}

export const learningPathCategory = {
  Id: 'learning-path-category',
  Name: "Parcours d'apprentissage",
  NumberOfCourses: 0,
  Slug: 'parcours'
}

const blankCategory = {
  Id: null,
  Name: null,
  NumberOfCourses: 0,
  Slug: null
}

export const freeCoursesTag = {
  Id: 'free-courses-tag',
  Name: 'Gratuite',
  Courses: null,
  NumberOfCourses: 0
}

export const cleanTag = {
  Id: '',
  Name: '',
  NumberOfCourses: 0,
  Photo: null,
  Slug: ''
}

const initialState = {
  categories: defaultCategories,
  learningPaths: [],
  tags: [],
  selectedCategory: defaultCategory,
  selectedTag: cleanTag,
  selectedLearningPathCategory: defaultCategory,
  selectedFreeCoursesTag: cleanTag,
  courses: [],
  take: defaultTake,
  skip: defaultSkip,
  learningPathTake: defaultLearningPathTake,
  learningPathSkip: defaultLearningPathSkip,
  count: 0,
  countLearningPaths: 0,
  allCoursesCategory: defaultCategory,
  loadingCourses: false,
  loadingTags: false,
  loadingCategories: false,
  loadingLearningPaths: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAN_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: blankCategory
      }
    case CATEGORIES_FETCH_REQUESTED:
      return {
        ...state,
        loadingCategories: true
      }
    case SELECT_TAGS_BY_CATEGORY:
      return {
        ...state,
        loadingCourses: true,
        courses: []
      }
    case SEE_MORE_COURSES:
      return {
        ...state,
        loadingCourses: true
      }
    case SELECT_CATEGORY_FROM_SLUG:
      return {
        ...state
      }
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: data,
        loadingCategories: false
      }
    case SELECT_CATEGORY:
      return {
        ...state,
        courses: [],
        selectedCategory: data,
        count: data.NumberOfCourses,
        selectedTag: cleanTag,
        take: defaultTake,
        skip: defaultSkip,
        loadingCourses: true,
        loadingTags: true,
        selectedLearningPathCategory: defaultCategory
      }
    case GET_TAGS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        tags: data,
        selectedLearningPathCategory: defaultCategory,
        loadingTags: false
      }
    case SELECT_TAGS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedTag: data,
        take: defaultTake
      }
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        ...data,
        take: defaultTake,
        skip: defaultSkip
      }
    case CREATE_ALL_COURSES_CATEGORY_DONE:
      return {
        ...state,
        allCoursesCategory: data,
        selectedTag: cleanTag
      }
    case SEE_MORE_COURSES_SUCCESS:
      return {
        ...state,
        courses: [
          ...state.courses,
          ...data.courses
        ],
        take: data.take,
        skip: data.skip,
        count: data.count
      }
    case SELECT_LEARNING_PATHS_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedLearningPathCategory: data,
        learningPaths: []
      }
    case SELECT_TAGS_BY_FREE_COURSES:
      return {
        ...state,
        selectedFreeCoursesTag: data
      }
    case GET_LEARNING_PATHS_SUCCESS:
      return {
        ...state,
        learningPaths: data.items,
        countLearningPaths: data.countLearningPaths,
        learningPathTake: defaultLearningPathTake,
        learningPathSkip: data.learningPathSkip,
        loadingLearningPaths: false
      }
    case SEE_MORE_LEARNING_PATHS_SUCCESS:
      return {
        ...state,
        learningPaths: [
          ...state.learningPaths,
          ...data.items
        ],
        learningPathTake: defaultLearningPathTake,
        learningPathSkip: data.learningPathSkip,
        countLearningPaths: data.countLearningPaths,
        loadingLearningPaths: false
      }
    case LOAD_LEARNING_PATHS:
    case SEE_MORE_LEARNING_PATHS:
      return {
        ...state,
        loadingLearningPaths: true
      }
    case LEARNING_PATH_CLEAN_TAGS:
      return {
        ...state,
        selectedLearningPathCategory: defaultCategory
      }
    case FREE_COURSES_CLEAN_TAGS:
      return {
        ...state,
        selectedFreeCoursesTag: cleanTag
      }
    case LEARNING_PATHS_FETCH_REQUESTED:
      return {
        ...state,
        learningPaths: []
      }
    case GET_LEARNING_PATHS_FAIL:
      return {
        ...state,
        loadingLearningPaths: false
      }
    case LOADING_COURSES_DONE_ACTION:
      return {
        ...state,
        loadingCourses: false
      }
    default:
      return state
  }
}

const getAutoAppliedCoupon = ({ user }) => user?.AutoAppliedCoupons

const getReferential = ({ referential }) => referential

const getShop = ({ support }) => support

export const createTheAllCoursesCategory = () => ({ type: CREATE_ALL_COURSES_CATEGORY })

export const fetchCategoriesAction = () => ({ type: CATEGORIES_FETCH_REQUESTED })

export const selectCategory = (category, initialCourses) => ({ type: SELECT_CATEGORY, data: category, initialCourses })

export const selectTagForCategory = tag => ({ type: SELECT_TAGS_BY_CATEGORY, tag })

export const SeeMore = _ => ({ type: SEE_MORE_COURSES })

export const SeeMoreLearningPaths = _ => ({ type: SEE_MORE_LEARNING_PATHS })

export const SelectCategoryFromSlugAction = slug => ({ type: SELECT_CATEGORY_FROM_SLUG, data: slug })

export const cleanSelectedCategoryAction = () => ({ type: CLEAN_SELECTED_CATEGORY })

export const getState = (state) => state

export const getLearningPathsAction = (categoryId, take) => ({ type: LEARNING_PATHS_FETCH_REQUESTED, categoryId, take })

export const selectLearningPathCategoryAction = categorySlug => ({ type: SELECT_LEARNING_PATHS_CATEGORY, categorySlug })

export const selectTagForFreeCoursesAction = tag => ({ type: SELECT_TAGS_BY_FREE_COURSES, data: tag })

export const cleanLearningPathTagsAction = () => ({ type: LEARNING_PATH_CLEAN_TAGS })

export const cleanFreeCoursesTagsAction = () => ({ type: FREE_COURSES_CLEAN_TAGS })

export const fetchCatalogCoursesReviewsAction = (courses) => ({ type: FETCH_CATALOG_COURSES_REVIEWS_REQUESTED, courses })

export const LoadingCoursesDoneAction = () => ({ type: LOADING_COURSES_DONE_ACTION })

function * fetchTagsByCategory ({ data }) {
  const isAllCoursesCategory = data?.Id === allCoursesId
  const isFreeCoursesCategory = data?.Id === freeCoursesCategory.Id
  const isLearningPathCategory = data?.Id === learningPathCategory.Id
  if (data?.Id && !isAllCoursesCategory && !isFreeCoursesCategory && !isLearningPathCategory) {
    const { catalog } = yield select(getState)
    try {
      const response = yield fetch(queries.getTagsForCategory(data.Id))
      const tags = response.filter(tag => tag.NumberOfCourses !== 0).sort((tag1, tag2) => tag2.NumberOfCourses - tag1.NumberOfCourses)
      const params = {
        take: 1,
        skip: 0,
        isFree: true
      }
      const freeCoursesCountResponse = yield fetch(queries.getCourses(catalog.selectedCategory.Slug, catalog.selectedTag.Id, params.take, params.skip, params.isFree))
      if (freeCoursesCountResponse?.Count) {
        tags.unshift({ ...freeCoursesTag, NumberOfCourses: freeCoursesCountResponse.Count })
      }
      yield put({ type: GET_TAGS_BY_CATEGORY_SUCCESS, data: tags })
      yield put({ type: CATEGORY_SELECTED, data: true })
    } catch (err) {
      yield put({ type: GET_TAGS_BY_CATEGORY_FAIL, err })
    }
  } else {
    yield put({ type: GET_TAGS_BY_CATEGORY_SUCCESS, data: [] })
    yield put({ type: CATEGORY_SELECTED, data: false })
  }
}

function * fetchCategories () {
  try {
    const response = yield fetch(queries.getCategories)
    yield put({ type: GET_CATEGORIES_SUCCESS, data: response.Items })
  } catch (err) {
    yield put({ type: GET_CATEGORIES_FAIL, err })
  }
}

function * fetchCourses ({ type, initialCourses }) {
  const { catalog } = yield select(getState)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const referential = yield select(getReferential)
  const shop = yield select(getShop)
  const courseCoupon = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon[0]?.Values?.find(coupon => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]) : null
  const promo = shop?.PromotionCourse
  switch (type) {
    case SEE_MORE_COURSES:
      catalog.skip += catalog.take
      break
    case SELECT_TAGS_BY_CATEGORY:
      catalog.skip = 0
      break
    case SELECT_CATEGORY:
      catalog.skip = 0
      break
    case GET_COURSES_REQUESTED:
      catalog.skip = 0
      break
    default:
      catalog.skip = 0
      break
  }
  const actionType = type === SEE_MORE_COURSES ? SEE_MORE_COURSES_SUCCESS : GET_COURSES_SUCCESS
  const currentTag = catalog.selectedTag || cleanTag
  const currentTagId = currentTag.Id === freeCoursesTag.Id ? cleanTag.Id : currentTag.Id
  const isFree = catalog.selectedCategory.Id === freeCoursesCategory.Id || currentTag.Id === freeCoursesTag.Id
  const selectedCategorySlug =
  catalog.selectedCategory.Id === freeCoursesCategory.Id
    ? catalog.selectedFreeCoursesTag.Slug
    : catalog.selectedCategory.Id === learningPathCategory.Id
      ? ''
      : catalog.selectedCategory.Slug
  try {
    const response = yield fetch(queries.getCourses(selectedCategorySlug, currentTagId, initialCourses || catalog.take, catalog.skip, isFree))
    const courses = response.Data
    const coursesWithReviews = yield all(courses.map(async course => {
      course.Reviews = []
      return course
    }))
    if (courseCoupon) {
      coursesWithReviews.forEach(course => {
        course.HasActivePromotion = true
        course.PricePromo = courseCoupon.Value
        course.DiscountPrice = courseCoupon.Value
        course.AlternativePricePromo = courseCoupon.Value * shop.PriceCoefficient
      })
    } else if (promo) {
      coursesWithReviews.forEach(course => {
        course.HasActivePromotion = true
        course.PricePromo = promo.DiscountPrice
        course.DiscountPrice = promo.DiscountPrice
        course.AlternativePricePromo = promo.AlternativePricePromo
        course.DiscountPercentage = promo.DiscountPercentage
      })
    }
    yield put({ type: actionType, data: { courses: coursesWithReviews, take: initialCourses || catalog.take, skip: catalog.skip, count: response.Count || catalog.count } })
    yield put(LoadingCoursesDoneAction())
    yield put(fetchCatalogCoursesReviewsAction(courses))
  } catch (err) {
    yield put({ type: GET_COURSES_FAIL, err })
  }
}

function * fetchCatalogCoursesReviews ({ courses }) {
  const { catalog } = yield select(getState)
  try {
    const coursesWithReviews = yield all(courses.map(async course => {
      const courseReviews = await fetch(queries.getCourseReviewsDetails(course.Slug))
      course.Reviews = courseReviews.Items
      return course
    }))
    ViewItemListDataLayer({ courses: [...catalog.courses, ...coursesWithReviews], listName: catalog.selectedCategory.Name })
  } catch (err) {
    yield put({ type: FETCH_CATALOG_COURSES_REVIEWS_FAILED, err })
  }
}

function * fetchLearningPaths ({ type, take }) {
  yield put({ type: LOAD_LEARNING_PATHS })
  const { catalog } = yield select(getState)
  const selectedCategoryId = catalog?.selectedLearningPathCategory?.Id === allLearningPathId ? null : catalog?.selectedLearningPathCategory?.Id
  switch (type) {
    case SEE_MORE_LEARNING_PATHS:
      catalog.learningPathSkip += catalog.learningPathTake
      break
    case LEARNING_PATHS_FETCH_REQUESTED:
      catalog.learningPathSkip = 0
      break
    default:
      catalog.learningPathSkip = 0
      break
  }
  const actionType = type === SEE_MORE_LEARNING_PATHS ? SEE_MORE_LEARNING_PATHS_SUCCESS : GET_LEARNING_PATHS_SUCCESS
  try {
    const response = yield fetch(queries.getLearningPaths(take || catalog.learningPathTake, catalog.learningPathSkip, selectedCategoryId))
    yield put({
      type: actionType,
      data: {
        items: response.Data,
        countLearningPaths: response.Count,
        learningPathTake: take || catalog.learningPathTake,
        learningPathSkip: catalog.learningPathSkip
      }
    })
  } catch (err) {
    yield put({ type: GET_LEARNING_PATHS_FAIL, err })
  }
}

function * fetchTheAllCoursesCategory () {
  const category = ''
  const tagId = ''
  const take = 1
  const skip = 0
  try {
    const response = yield fetch(queries.getCourses(category, tagId, take, skip))
    const allCategories = {
      Id: allCoursesId,
      Name: 'Toutes les formations',
      NumberOfCourses: response.Count,
      Slug: ''
    }
    yield put({ type: CREATE_ALL_COURSES_CATEGORY_DONE, data: allCategories })
  } catch (err) {
    yield put({ type: CREATE_ALL_COURSES_CATEGORY_FAIL, err })
  }
}

function * selectCategoryBySlug ({ data }) {
  const { catalog } = yield select(getState)
  const categories = [allCoursesId, learningPathCategory, freeCoursesCategory, ...catalog.categories]
  const selectedCategory = categories.length && categories.find(category => category.Slug === data)
  if (selectedCategory) {
    yield put(selectCategory(selectedCategory === allCoursesId ? defaultCategory : selectedCategory))
  } else {
    yield put(push(decorateUrl(pageNotFoundPath)))
  }
}

function * selectTag ({ tag }) {
  const { catalog } = yield select(getState)
  try {
    const data = catalog.selectedTag.Id === tag.Id ? cleanTag : tag
    yield put({ type: SELECT_TAGS_BY_CATEGORY_SUCCESS, data })
    yield put({ type: GET_COURSES_REQUESTED, data })
  } catch (err) {
    yield put({ type: SELECT_TAGS_BY_CATEGORY_FAILED, err })
  }
}

function * selectLearningPathCategory ({ categorySlug = '' }) {
  const { catalog } = yield select(getState)
  const categories = [defaultLearningPathsCategory, ...catalog.categories]
  const selectedCategory = categories.length && categories.find(category => category.Slug === categorySlug)
  if (selectedCategory) {
    const data = selectedCategory?.Id !== allLearningPathId ? selectedCategory : defaultLearningPathsCategory
    data.Id = selectedCategory?.Id !== allLearningPathId ? data?.Id : null
    yield put({ type: SELECT_LEARNING_PATHS_CATEGORY_SUCCESS, data })
  } else {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put({ type: SELECT_LEARNING_PATHS_CATEGORY_FAILED })
  }
}

export function * catalogRootSaga () {
  yield takeLatest(CATEGORIES_FETCH_REQUESTED, fetchCategories)
  yield takeLatest(SELECT_CATEGORY, fetchTagsByCategory)
  yield takeEvery([GET_COURSES_REQUESTED, SELECT_CATEGORY, SEE_MORE_COURSES, SELECT_TAGS_BY_FREE_COURSES], fetchCourses)
  yield takeLatest([CREATE_ALL_COURSES_CATEGORY], fetchTheAllCoursesCategory)
  yield takeLatest(SELECT_CATEGORY_FROM_SLUG, selectCategoryBySlug)
  yield takeLatest(SELECT_TAGS_BY_CATEGORY, selectTag)
  yield takeLatest([LEARNING_PATHS_FETCH_REQUESTED, SEE_MORE_LEARNING_PATHS, SELECT_LEARNING_PATHS_CATEGORY_SUCCESS], fetchLearningPaths)
  yield takeLatest(SELECT_LEARNING_PATHS_CATEGORY, selectLearningPathCategory)
  yield takeLatest(FETCH_CATALOG_COURSES_REVIEWS_REQUESTED, fetchCatalogCoursesReviews)
}

const queries = {
  getCategories: `${BASE_URL_WEB}/Category/list`,
  getTagsForCategory: categoryId => `${BASE_URL_WEB}/Category/tag/${categoryId}`,
  getCourses: (category, tagId, take, skip, isFree) => `${BASE_URL_WEB}/Course/?${category ? `CategorySlug=${category}` : ''}${tagId ? `&tagId=${tagId}` : ''}${take !== undefined ? `&Take=${take}` : ''}${isFree ? `&IsFree=${isFree}` : ''}&Skip=${skip}&OrderedByAsc=false&OrderBy=${tagId ? 'IsFree,' : ''}PublicationDate`,
  getCourseReviewsDetails: slug => `${BASE_URL_WEB}/Review/getCourseReviewsDetails/${slug}`,
  getLearningPaths: (take, skip, categoryId) => `${BASE_URL_WEB}/CoursePath?OrderBy=ActivationDate&Take=${take}&Skip=${skip}${categoryId ? `&CategoryId=${categoryId}` : ''}`
}
const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
const CATEGORIES_FETCH_REQUESTED = 'CATEGORIES_FETCH_REQUESTED'
const SELECT_CATEGORY = 'SELECT_CATEGORY'
const GET_TAGS_BY_CATEGORY_SUCCESS = 'GET_TAGS_BY_CATEGORY_SUCCESS'
const GET_TAGS_BY_CATEGORY_FAIL = 'GET_TAGS_BY_CATEGORY_FAIL'
const SELECT_TAGS_BY_CATEGORY = 'SELECT_TAGS_BY_CATEGORY'
const SELECT_TAGS_BY_CATEGORY_SUCCESS = 'SELECT_TAGS_BY_CATEGORY_SUCCESS'
const SELECT_TAGS_BY_CATEGORY_FAILED = 'SELECT_TAGS_BY_CATEGORY_FAILED'
const GET_COURSES_REQUESTED = 'GET_COURSES_REQUESTED'
const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS'
const GET_COURSES_FAIL = 'GET_COURSES_FAIL'
const SEE_MORE_COURSES = 'SEE_MORE_COURSES'
const CREATE_ALL_COURSES_CATEGORY = 'CREATE_ALL_COURSES_CATEGORY'
const CREATE_ALL_COURSES_CATEGORY_DONE = 'CREATE_ALL_COURSES_CATEGORY_DONE'
const CREATE_ALL_COURSES_CATEGORY_FAIL = 'CREATE_ALL_COURSES_CATEGORY_FAIL'
const SEE_MORE_COURSES_SUCCESS = 'SEE_MORE_COURSES_SUCCESS'
const SELECT_CATEGORY_FROM_SLUG = 'SELECT_CATEGORY_FROM_SLUG'
const CLEAN_SELECTED_CATEGORY = 'CLEAN_SELECTED_CATEGORY'
const GET_LEARNING_PATHS_SUCCESS = 'GET_LEARNING_PATHS_SUCCESS'
const GET_LEARNING_PATHS_FAIL = 'GET_LEARNING_PATHS_FAIL'
const LEARNING_PATHS_FETCH_REQUESTED = 'GET_LEARNING_PATHS_REQUESTED'
const SEE_MORE_LEARNING_PATHS = 'SEE_MORE_LEARNING_PATHS'
const SEE_MORE_LEARNING_PATHS_SUCCESS = 'SEE_MORE_LEARNING_PATHS_SUCCESS'
const SELECT_LEARNING_PATHS_CATEGORY = 'SELECT_LEARNING_PATHS_CATEGORY'
const SELECT_LEARNING_PATHS_CATEGORY_SUCCESS = 'SELECT_LEARNING_PATHS_CATEGORY_SUCCESS'
const SELECT_LEARNING_PATHS_CATEGORY_FAILED = 'SELECT_LEARNING_PATHS_CATEGORY_FAILED'
const SELECT_TAGS_BY_FREE_COURSES = 'SELECT_TAGS_BY_FREE_COURSES'
const FREE_COURSES_CLEAN_TAGS = 'FREE_COURSES_CLEAN_TAGS'
const LOAD_LEARNING_PATHS = 'LOAD_LEARNING_PATHS'
const LEARNING_PATH_CLEAN_TAGS = 'LEARNING_PATH_CLEAN_TAGS'
const FETCH_CATALOG_COURSES_REVIEWS_REQUESTED = 'FETCH_CATALOG_COURSES_REVIEWS_REQUESTED'
const FETCH_CATALOG_COURSES_REVIEWS_FAILED = 'FETCH_CATALOG_COURSES_REVIEWS_FAILED'
const LOADING_COURSES_DONE_ACTION = 'LOADING_COURSES_DONE_ACTION'
