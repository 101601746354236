import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { videoProgressionStyle } from './videoProgression.style'
import { Icon } from '../icon/icon'
import { VideoStateMap, ViewState } from '../../constants/videoConst'
import TypographyElement from '../typographyElement/typographyElement'
import { Icons } from '../..'

const VideoProgression = ({ viewState, videoState, onVideoClick, videoTitle, videoDuration, link, generateLink }) => {
  const theme = useTheme()
  const useStyle = createUseStyles(videoProgressionStyle)
  const { videoBody, videoIcon, courseTime, time, titleGroup } = useStyle({ viewState })
  return (
    <div
      className={videoBody}
      onClick={(e) => {
        e.preventDefault()
        onVideoClick()
      }}
    >
      <div className={titleGroup}>
        <Icon style={videoIcon} iconName={viewState === ViewState.done ? Icons.check : VideoStateMap[videoState].icon} />
        <TypographyElement
          variant='body1'
          color={viewState === ViewState.playing ? theme.neutral[400] : theme.neutral[100]}
          Component={generateLink ? 'a' : 'span'}
          maxLines={1}
          spacing='0 4px 0 0'
          title={videoTitle}
          href={generateLink ? link : null}
        >
          {videoTitle}
        </TypographyElement>
      </div>
      <div className={courseTime}>
        <TypographyElement
          color={viewState === ViewState.playing ? theme.neutral[400] : theme.primary[400]}
          variant='caption'
          className={time}
          lineHeight='13px'
          spacing='5px 4px 4px 7px'
        >
          {videoDuration}
        </TypographyElement>
      </div>
    </div>
  )
}

VideoProgression.propTypes = {
  /** link to the video */
  link: PropTypes.string,
  /** state of the video */
  viewState: PropTypes.oneOf(['done', 'playing', 'unseen']),
  /** state of the video */
  videoState: PropTypes.oneOf(['locked', 'unlocked', 'video']),
  /** select video to play  */
  onVideoClick: PropTypes.func,
  /** the title of the video */
  videoTitle: PropTypes.string,
  /** the video duration */
  videoDuration: PropTypes.string
}

export default VideoProgression
